<template>
  <section>
    <div class="alert alert-info">
      <h4><i class="icon fa fa-info"></i> {{ $t("attention") }}</h4>
      {{ $t("private_tokens_instructions") }}
    </div>

    <div class="form-box">
      <div class="row">
        <div
          class="col-md-12 col-xs-12 form-group"
          :class="{'has-error': !isValidDescription}"
        >
          <label>
            {{ $t("description") }}
            <Tooltip
              :title="$parent.$t('hints.public_access_links_description')"
            />
          </label>
          <input
            type="text"
            class="form-control"
            :placeholder="$t('description') + ' (' + $t('required') + ')'"
            v-model="formData.description"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-xs-6 form-group">
          <label>
            {{ $tc("screen", 1) }}
            <Tooltip :title="$parent.$t('hints.public_access_links_screen')" />
          </label>
          <ScreenSelector v-model="formData.screen_id" />
        </div>
        <div
          class="col-md-5 col-xs-12 form-group"
          :class="{'has-error': !isValidURL}"
        >
          <label>
            {{ $t("redirect_url") }}
            <Tooltip :title="$parent.$t('hints.public_access_links_url')" />
          </label>

          <input
            type="text"
            class="form-control"
            :placeholder="$t('redirect_url')"
            v-model="formData.redirect_url"
          />
        </div>

        <div
          class="col-md-2 col-xs-12 form-group"
          :class="{'has-error': !isValidTimeout}"
        >
          <label style="white-space: nowrap">
            {{ $t("access_timeout") }}
            <Tooltip :title="$parent.$t('hints.public_access_links_timeout')" />
          </label>
          <div class="input-group">
            <input
              type="number"
              class="form-control text-center"
              :min="references.min_timeout"
              :max="references.max_timeout"
              v-model="formData.access_timeout"
            />
            <div class="input-group-addon">
              {{ $tc("minute", 2) }}
            </div>
          </div>
        </div>

        <div class="col-md-2 col-xs-12 form-group">
          <label>
            {{ $t("expires_at") }}
            <Tooltip
              :title="$parent.$t('hints.public_access_links_expires_at')"
            />
          </label>
          <DatetimePicker id="expires_at" v-model="formData.expires_at" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="btn-group pull-right">
            <button type="button" class="btn btn-default" @click="reset">
              <i class="fa fa-times"></i>
              {{ $t("cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="save"
              :disabled="!isValid"
            >
              <i class="fa fa-floppy-o"></i>
              <template v-if="id">
                {{ $t("save_link") }}
              </template>
              <template v-else>
                {{ $t("create_link") }}
              </template>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 10px">
      <div class="col-md-12">
        <SearchableTable
          class="searchable-table"
          v-if="items"
          :searchEnabled="false"
          :items="items"
          :fields="fields"
          :commands="commands"
          @command="onCommand"
          @select="onSelect"
        >
          <template v-slot:toggle="entry">
            <span @click.stop.prevent="toggleItem(entry.item)">
              {{ $t(entry.item.enabled ? "yes" : "no") }}
              <i
                :class="
                  busy
                    ? 'fa fa-refresh fa-spin'
                    : entry.item.enabled
                    ? 'fa fa-toggle-on text-green'
                    : 'fa fa-toggle-off'
                "
              ></i>
            </span>
          </template>
        </SearchableTable>
        <QRCodeViewer
          v-if="qrcodeItem && qr_content"
          :content="qr_content"
          @close="qrcodeItem = null"
          :filename="`qr_${connector.name}.jpg`"
        >
          <template v-slot:contentAction="entry">
            <a :href="entry.content" @click.stop.prevent="copy()">
              <i class="fa fa-copy"></i>
              <span style="margin-left: 5px">{{ entry.content }}</span>
            </a>
          </template>
        </QRCodeViewer>
      </div>
    </div>
  </section>
</template>

<script>
import TokenService from "@/services/token.js";
import DatetimePicker from "@/components/widgets/datetime-picker";
import ScreenSelector from "@/components/editor/screen-selector.vue";
import SearchableTable from "@/components/searchable-table.vue";
import QRCodeViewer from "@/components/widgets/qrcode-viewer.vue";
import Tooltip from "@/components/tooltip.vue";

const initialState = () => {
  return {
    formData: {
      enabled: true,
      access_timeout: 5,
      expires_at: null,
      redirect_url: "",
      screen_id: "",
      description: ""
    },
    id: "",
    etag: "",
    busy: false,
    items: null,
    qrcodeItem: null
  };
};

export default {
  name: "FormConnectorPublicTokens",
  components: {
    DatetimePicker,
    ScreenSelector,
    SearchableTable,
    QRCodeViewer,
    Tooltip
  },
  props: {
    connector: {
      type: Object,
      required: true,
      default: () => null
    }
  },
  data() {
    return initialState();
  },
  computed: {
    fields() {
      let self = this;
      return [
        {
          name: "screen_id",
          title: "screen",
          parser(item) {
            return (
              (self.screenList || []).find(
                (screen) => screen.id == item.screen_id
              ) || {
                name: self.$t("item_not_found")
              }
            ).name;
          }
        },
        {
          name: "description",
          title: "description"
        },
        {
          name: "redirect_url",
          title: "redirect_url",
          parser(item) {
            return item.redirect_url || self.$t("page.sign_in_page");
          }
        },
        {
          name: "access_timeout",
          title: "access_timeout",
          style: {
            "text-align": "center"
          },
          parser(item) {
            return `${item.access_timeout} min`;
          }
        },
        {
          name: "expires_at",
          title: "expires_at",
          parser: (item) => {
            let expires_at = item.expires_at;
            if (!expires_at) return this.$t("undefined");
            return moment(expires_at).format("DD/MM/YYYY HH:mm");
          }
        }
      ];
    },
    commands() {
      if (this.$can("manage", "TokenAcessoRemotoEscrita")) {
        return [
          {
            name: "toggleItem",
            title: (item) => {
              return this.$tc(item.enabled ? "enabled" : "disabled", 1);
            },
            icon: (item) => {
              return item.enabled
                ? "fa fa-toggle-on text-green"
                : "fa fa-toggle-off";
            }
          },
          {name: "edit", title: "edit", icon: "fa fa-pencil"},
          {
            name: "copy",
            title: this.$t("copy", {item: "url"}),
            icon: "fa fa-copy"
          },
          {name: "qrcode", title: "qrcode", icon: "fa fa-qrcode"},
          {name: "remove", title: "remove", icon: "fa fa-trash"}
        ];
      }
      return [];
    },
    isValidURL() {
      return Boolean(
        !this.formData.redirect_url ||
          this.$utils.isValidURL(this.formData.redirect_url)
      );
    },
    isValidTimeout() {
      let timeout = parseInt(this.formData.access_timeout);
      return Boolean(
        timeout >= this.references.min_timeout &&
          timeout <= this.references.max_timeout
      );
    },
    isValidDescription() {
      return Boolean(this.formData.description);
    },
    isValid() {
      return Boolean(
        this.formData.description && this.isValidURL && this.isValidTimeout
      );
    },
    screenList() {
      return (this.$store.getters["dashboard/screens"] || []).filter(
        ({id}) => id > -1
      );
    },
    references() {
      let ret = this?.$root?.config?.references?.public_access || {
        default_timeout: 5,
        min_timeout: 1,
        max_timeout: 15
      };
      return ret;
    },
    payload() {
      let payload = JSON.parse(JSON.stringify(this.formData));
      payload.connector_id = this.connector.id;
      payload.access_timeout = parseInt(this.formData.access_timeout);
      payload.screen_id = parseInt(payload.screen_id);
      if (this.id) {
        payload.id = this.id;
      }
      if (this.etag) {
        payload.etag = this.etag;
      }
      if (payload.expires_at) {
        payload.expires_at = moment(payload.expires_at)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss");
      } else {
        payload.expires_at = null;
      }
      return payload;
    },
    qr_content() {
      return (this.qrcodeItem && this.srv.toPublicUrl(this.qrcodeItem)) || "";
    }
  },
  watch: {
    busy(n) {
      this.$emit("loading", n);
    }
  },
  methods: {
    reset() {
      let items = JSON.parse(JSON.stringify(this.items));
      Object.assign(this.$data, initialState());
      this.formData.screen_id = this?.connector?.screen_id || "";
      this.formData.access_timeout = this.references.default_timeout;
      this.formData.expires_at = null;
      this.items = items;
    },
    save() {
      if (!this.isValid) return;
      if (this.busy) return;
      this.busy = true;
      this.srv.save(this.payload).then((response) => {
        this.busy = false;
        if (response && response.id) {
          let ix = (this.items || []).findIndex(
            ({id}) => parseInt(id) == response.id
          );
          if (ix >= 0) {
            this.$set(this.items, ix, response);
          } else {
            this.reset();
            this.fetchTokens();
          }
        } else {
          if (response) {
            this.$swal({
              title: this.$t("an_error_has_occurred"),
              text: response,
              icon: "error"
            });
          }
        }
      });
    },
    fetchTokens() {
      let query = {connector_id: this.connector.id};
      this.srv.fetch(query).then((response) => {
        if (response && response.length) {
          var items = response.map((item) => {
            item.access_timeout =
              item.access_timeout < this.references.min_timeout
                ? this.references.min_timeout
                : item.access_timeout > this.references.max_timeout
                ? this.references.max_timeout
                : item.access_timeout;
            return item;
          });
          this.$set(this, "items", items);
        } else {
          this.$set(this, "items", null);
        }
        this.$nextTick(() => {
          this.busy = false;
        });
      });
    },
    onCommand(command) {
      if (command.name in this) {
        this[command.name](command.target);
      }
    },
    onSelect(item) {
      this.copy(item);
    },
    edit(item) {
      this.id = item.id;
      this.etag = item.etag;
      this.$set(this.formData, "screen_id", item.screen_id);
      this.$set(this.formData, "redirect_url", item.redirect_url);
      this.$set(this.formData, "description", item.description ?? "");
      this.$set(this.formData, "expires_at", item.expires_at);
      this.$set(this.formData, "access_timeout", item.access_timeout);
      this.$set(this.formData, "enabled", item.enabled);
    },
    remove(item) {
      if (this.busy) return;
      let entry = item || null;
      if (!entry && this.id && this.etag) {
        entry = {id: this.id, etag: this.etag};
      }
      this.busy = true;
      this.srv.remove(entry).then(() => {
        this.reset();
        this.fetchTokens();
      });
    },
    copy(item) {
      let url = this.srv.toPublicUrl(item || this.qrcodeItem) || "";
      this.$utils.toClipboard(url);
      let key =
        navigator.userAgent.toUpperCase().indexOf("FIREFOX") >= 0
          ? "Ctrl+Shift+P"
          : "Ctrl+Shift+N";
      this.toast(this.$t("link_copied_to_clipboard"), "success");
    },
    toast(msg, type) {
      this.$toasted.show(msg, {
        singleton: true,
        type: type == "error" ? "error" : "success",
        icon: type == "error" ? "fa-exclamation-triangle" : "fa-check",
        iconPack: "fontawesome",
        position: "bottom-right",
        duration: 1000
      });
    },
    toggleItem(item) {
      this.edit(item);
      this.formData.enabled = !this.formData.enabled;
      this.save();
    },
    navigate(item) {
      if (!item.enabled) return;
      this.$utils.navigate(this.srv.toPublicUrl(item), {new_tab: true});
    },
    qrcode(item) {
      this.qrcodeItem = item;
    },
    fmtExpiresAt(isoDT) {
      return isoDT ? moment(isoDT).format("DD/MM/YY H:mm") : "";
    }
  },
  created() {
    this.srv = new TokenService("public");
    this.reset();
    this.fetchTokens();
  },
  beforeDestroy() {
    this.$toasted.clear();
  }
};
</script>

<style scoped>
.form-box {
  border-radius: 5px;
  background-color: whitesmoke;
  border: 1px solid lightgray;
  padding: 20px 10px;
  margin: 0 0 20px 0;
}

.no-bold {
  font-weight: 400;
}

.searchable-table {
  overflow-x: auto;
}
</style>
